// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

table th {
  white-space: nowrap;
}
table td {
  white-space: nowrap;
}

.ifp-topbar-logo {
  height: 30px;
  margin-top: -2px;
}

.page-top {
  .app-title-container {
    text-align: center;
    margin: 8px 0 28px 0;

    .title-ja {
      font-size: 28px;
      letter-spacing: 1px;
    }
    .title-en {
      margin: -8px 0 0 0;
      font-size: 32px;
      font-weight: bold;
      color: #158cba;
      letter-spacing: 4px;
    }
  }

  .btn-subsystem {
    height: 80px;
    width: 100%;
    line-height: 66px;
    text-align: center;
    font-size: 22px;
    background-color: #158cba;
  }
}
